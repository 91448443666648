import { MetaSwiper, getPosition } from "./meta-settings.js";

$(document).ready(function ($) {
  document.body.classList.remove("preload");

  const idSite = document.getElementById("site");
  const root = document.documentElement;

  const getRootStyle = function (variable) {
    return getComputedStyle(root).getPropertyValue(variable);
  };

  // Simplebar
  // const mainSimplebar = new SimpleBar(document.querySelector('#site'));

  // Theme change
  const themeToggle = document.querySelector(".theme-btn");
  const themeMatchMedia = window.matchMedia("(prefers-color-scheme: dark)");

  themeToggle.addEventListener("click", (e) => {
    document.documentElement.classList.toggle("dark");
    themeToggle.classList.toggle("dark-active");

    const currentSystemTheme = themeMatchMedia.matches ? "dark" : "light";

    if (
      (themeToggle.classList.contains("dark-active") &&
        currentSystemTheme === "dark") ||
      (!themeToggle.classList.contains("dark-active") &&
        currentSystemTheme === "light")
    ) {
      localStorage.setItem("theme", "auto");
    } else {
      localStorage.setItem(
        "theme",
        themeToggle.classList.contains("dark-active") ? "dark" : "light"
      );
    }
  });

  window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
    const lsTheme = localStorage.getItem("theme");

    if (lsTheme === "auto" || !lsTheme) {
      if (e.matches) {
        document.documentElement.classList.add("dark");
        themeToggle.classList.add("dark-active");
      } else {
        document.documentElement.classList.remove("dark");
        themeToggle.classList.remove("dark-active");
      }
    } else if (
      (lsTheme === "light" && !e.matches) ||
      (lsTheme === "dark" && e.matches)
    ) {
      localStorage.setItem("theme", "auto");
    }
  });

  //cancel submitting the form when click 'enter' button and then click on the search button
  $(window).keydown(function (event) {
    if ($("#keywords").is(":focus")) {
      if (event.keyCode == 13) {
        event.preventDefault();
        $("#button-search").click();
      }
    }
  });

  // Sidebar menu items
  const categoryFirstListItems = document.querySelectorAll(
    ".sidebar__categories .category:first-of-type .category__name"
  );
  const categoryListItems = document.querySelectorAll(
    ".sidebar__categories .category__name"
  );

  categoryFirstListItems.forEach((item1) => {
    if (!item1.nextElementSibling) {
      // item1.parentElement.classList.add('empty');
      item1.parentElement.removeAttribute("data-accordion");
      item1.removeAttribute("data-control");
    }
  });

  $("#category-menu [data-accordion]").accordion({
    transitionSpeed: 300,
    transitionEasing: "ease-in-out",

    controlElement: ".category__name",
    contentElement: ".subcategory",
    groupElement: "#category-menu",

    singleOpen: true,
  });

  categoryListItems.forEach((item, i) => {
    item.addEventListener("click", function () {
      categoryListItems.forEach((selected) => {
        selected.parentElement.classList.remove("selected");
      });
      item.parentElement.classList.add("selected");
    });
  });

  const sidebar = document.querySelector(".sidebar");
  const sidebarContent = document.querySelector(".sidebar__content");
  const sidebarLogo = document.querySelector(".sidebar__logo");

  $(window).on("load resize orientationchange", function () {
    sidebarContent.style.maxHeight =
      window.innerHeight -
      sidebarLogo.getBoundingClientRect().height -
      stringToNum(getComputedStyle(sidebarLogo).marginBottom) -
      stringToNum(getComputedStyle(sidebar).paddingTop) -
      stringToNum(getComputedStyle(sidebar).paddingBottom) +
      "px";
  });

  // Burger menu btn
  const headerMenuBtn = document.querySelector(".header .menu-btn");
  const sidebarCloseBtn = document.querySelector(".sidebar .menu-btn");
  const mainContent = document.querySelector(".main-content");

  headerMenuBtn.addEventListener("click", function () {
    this.classList.add("active");
    sidebar.classList.add("active");
    mainContent.classList.add("active");

    // scrollLock.disablePageScroll(
    //   document.querySelector(".sidebar .simplebar-content")
    // );
  });

  sidebarCloseBtn.addEventListener("click", function () {
    headerMenuBtn.classList.remove("active");
    sidebar.classList.remove("active");
    mainContent.classList.remove("active");

    // scrollLock.enablePageScroll();
  });

  document.addEventListener("click", function (e) {
    if (e.target.className == "main-content active") {
      headerMenuBtn.classList.remove("active");
      sidebar.classList.remove("active");
      mainContent.classList.remove("active");

      // scrollLock.enablePageScroll();
    }
  });

  // Check if searched tags and categories are empty
  checkSearchList();

  // Suggested tag result (close by clicking out of area)
  document.addEventListener("click", function (e) {
    if (
      e.target.className != "result-item" &&
      e.target.className != "suggests_result"
    ) {
      document.querySelector(".suggests_result").innerHTML = "";
    }
  });

  // Copy text in post blockquotes
  let codeBlocks = Array.from(document.querySelectorAll(".cdx-code"));

  codeBlocks.map((block) => {
    let copyBtn = `<span class="btn-copy square icon-copy" onclick="copyToClipboardElement(this.parentElement)"></span>`;
    block.insertAdjacentHTML("beforeend", copyBtn);
  });

  // Media gallery
  const mediaSubSwiper = new Swiper(".media-sub-swiper", {
    slidesPerView: 3,
    spaceBetween: 10,
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  });

  const mediaMainSwiper = new Swiper(".media-main-swiper", {
    spaceBetween: 30,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    allowTouchMove: false,
    noSwiping: true,
    thumbs: {
      swiper: mediaSubSwiper,
    },
  });

  // Tabs
  const tabBtns = Array.from(document.getElementsByClassName("tabs__btn"));
  const tabContents = Array.from(
    document.getElementsByClassName("tabs__content")
  );

  const copyTabContentBtns = Array.from(
    document.querySelectorAll(".tabs .tabs__btn .btn")
  );

  copyTabContentBtns.forEach((btn, i) => {
    btn.addEventListener("click", function () {
      copyToClipboardElement(tabContents[i]);
    });
  });

  tabBtns.forEach((btn, i) => {
    btn.addEventListener("click", function () {
      tabBtns.forEach((item) => item.classList.remove("active"));
      tabContents.forEach((item) => item.classList.remove("active"));

      btn.classList.add("active");
      tabContents[i].classList.add("active");
    });
  });

  const contentSticky = document.getElementById("content-sticky");
  const rightSidebar = document.getElementById("sidebar-right");
  if (rightSidebar) {
    const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
    const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
    const headerHeight = +getRootStyle("--header-height").replace("px", "");

    let lastScrollTop = 0;

    // if flag true === scrolling down else scrolling up
    let flag = null;

    // if flagHeight true === sidebar is higher than viewport height
    let flagHeight =
      document.querySelector(".sidebar__inner").getBoundingClientRect().height >
      window.innerHeight - headerHeight;

    function calcHeightOfSidebar(flag) {
      const contentStickyTop = getPosition(contentSticky).y;
      const sidebarInnerTop = getPosition(sidebarInner).y;
      sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

      if (flag) {
        // scroll down
        sidebarInner.style.top = "";
        sidebarInner.style.bottom = `${
          -headerHeight +
          window.innerHeight -
          sidebarInner.getBoundingClientRect().height
        }px`;
      } else {
        // scroll up
        sidebarInner.style.bottom = "";
        sidebarInner.style.top = `${Math.min(
          window.innerHeight - sidebarInner.getBoundingClientRect().height,
          headerHeight
        )}px`;
      }
      flagHeight =
        sidebarInner.getBoundingClientRect().height >
        window.innerHeight - headerHeight;
    }

    function resetSticky() {
      sidebarInner.style.bottom = "";
      sidebarInner.style.top = `${headerHeight}px`;
      sidebarBuffer.style.height = "0";
    }

    if (!flagHeight) {
      resetSticky();
    } else {
      calcHeightOfSidebar(false);
    }

    window.addEventListener("scroll", function () {
      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (!flagHeight) {
        resetSticky();
      }

      if (st > lastScrollTop && !flag) {
        // scroll down
        calcHeightOfSidebar(flag);
        flag = true;
      } else if (st < lastScrollTop && flag) {
        // scroll up
        calcHeightOfSidebar(flag);
        flag = false;
      }
      lastScrollTop = st <= 0 ? 0 : st;
    });

    window.addEventListener("resize", function (e) {
      if (!flagHeight) {
        resetSticky();
      }

      calcHeightOfSidebar(true);
      calcHeightOfSidebar(false);
    });
  }
});

// Check if searched tags and categories are empty
window.checkSearchList = function checkSearchList() {
  const searchTags = document.querySelector("#list_tags");
  const searchCategories = document.querySelector("#search-category");

  if (searchTags.childElementCount <= 0) {
    searchTags.classList.add("disabled");
  } else {
    searchTags.classList.remove("disabled");
  }

  if (searchCategories.childElementCount <= 0) {
    searchCategories.classList.add("disabled");
  } else {
    searchCategories.classList.remove("disabled");
  }
};

// Copy to clipboard selected element
window.copyToClipboardElement = function copyToClipboardElement(elem) {
  let range = document.createRange();
  range.selectNode(elem);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  noticeInfo("Copied to the clipboard");
};

// Copy to clipboard
window.copyToClipboard = function copyToClipboard() {
  let range = document.createRange();
  range.selectNode(this);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  noticeInfo("Copied to the clipboard");
};

// string to number
window.stringToNum = function stringToNum(string) {
  return parseInt(string.replace(/[a-z]/g, ""));
};

// tags list functions (search)
window.fillTag = function fillTag(el) {
  var code = trim($(el).text());
  $("#keywords").val(code);
  $("#button-search").click();
};

window.closeSuggest = function closeSuggest() {
  $(".suggests_result").html("");
};

window.suggestTag = function suggestTag(el) {
  if (trim($(el).val())) {
    load("page/tags_list", "tag#keywords");
  }
};

const bookmarksSection = document.getElementsByClassName(`bookmarks-sec`)[0];
if (bookmarksSection) {
  const posts = bookmarksSection.querySelector(".post-sec__posts");
  const sections = bookmarksSection.querySelector(".post-sec__sections");
  const spanArr = document.querySelectorAll(".post-sec__title span");

  spanArr.forEach((elem, index) => {
    elem.addEventListener("click", function () {
      if (elem.classList.contains("active")) return;

      spanArr[+!!!index].classList.remove("active");
      elem.classList.add("active");

      if (!!index) {
        posts.classList.remove("active");
        sections.classList.add("active");
      } else {
        posts.classList.add("active");
        sections.classList.remove("active");
      }

      window.cqApi.reevaluate(false, function () {
        // Do something after all elements were updated
      });
    });
  });
}
